import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import i18n from "./components/LangConfig"; 





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>  //react owl js
    <App/>
  // </React.StrictMode>
);